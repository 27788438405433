// create a client component
"use client";
import { usePathname, useSearchParams } from "next/navigation";
import Script from "next/script";
import { useEffect } from "react";
import { pageView } from "../../lib/gtm";

const GTM = process.env.NEXT_PUBLIC_GTM_CODE

const Analytics = () => {
	const pathname = usePathname();
	const searchParams = useSearchParams();

	useEffect(() => {
		if (pathname) {
			pageView(pathname);
		}
	}, [pathname, searchParams]);

	if (process.env.NEXT_PUBLIC_VERCEL_ENV !== "production") {
		return null;
	}

	return (
		<>
			<noscript>
				<iframe
					src={`https://www.googletagmanager.com/ns.html?id=${GTM}`}
					height="0"
					width="0"
					style={{ display: "none", visibility: "hidden" }}
				/>
			</noscript>
			<Script
				id="gtm-script"
				strategy="afterInteractive"
				dangerouslySetInnerHTML={{
					__html: `
						(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
						new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
						j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
						'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
						})(window,document,'script','dataLayer','${GTM}');
					`,
				}}
			/>
		</>
	);
};

export default Analytics;
